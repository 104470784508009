import { ShaderMaterial, Color } from 'three'
import { extend } from 'react-three-fiber'

class FlowShader extends ShaderMaterial {
  constructor() {
    super({
      vertexShader: `  uniform float iGlobalTime;
      uniform vec2 iResolution;
      uniform vec4 iMouse;
      uniform sampler2D iChannel0;
      uniform sampler2D iChannel1;
      varying vec2 vUv;
      void main() {
        vUv = uv;
        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0 );
        gl_Position = projectionMatrix * mvPosition;
}`,
      fragmentShader: `
      uniform float iGlobalTime;
      uniform vec2 iResolution;
      uniform vec4 iMouse;
      uniform float audio1;
      uniform sampler2D iChannel0;
      uniform sampler2D iChannel1;
      
      #define t iGlobalTime
      mat2 m(float a){float c=cos(a), s=sin(a);return mat2(c,-s,s,c);}
      float map(vec3 p){
        p.xz*= m(1.1);p.xy*= m(.4);
        vec3 q = p*3.+t;
          return  p.x*p.y * length(p+vec3(cos(.5)))*log(length(p)+.1) + sin(q.x+sin(q.z+sin(q.y)))*.5 - 1.  ;
      }
      
      void main(){
        vec2 p = gl_FragCoord.xy/iResolution.y - vec2(0.8,.5) ;
      
          vec3 cl = vec3(0.);
          float d = .5;
          for(int i=0; i<=5; i++) {
          vec3 p = vec3(.1,-.1,4.) + normalize(vec3(p, -1.5))*d ;
              float rz = map(p);
              float f =  clamp((rz - map(p+.11))*.7*cos(iGlobalTime*.1)*p.x, -.9, 1. );
              vec3 l = vec3(0.18, .19, 0.21) + vec3(1., 1.,1.)*f;
          cl = cl*l + (1.-smoothstep(0., 2.5, rz))*.7*l;
          d += min(rz, 1.0 );
        }
          gl_FragColor = vec4(cl, 1.);
      }`,
      uniforms: {
        texture: { value: null },
        hasTexture: { value: 0 },
        scale: { value: 0 },
        shift: { value: 0 },
        opacity: { value: 1 },
        color: { value: new Color('black') }
      }
    })
  }

  set scale(value) {
    this.uniforms.scale.value = value
  }

  get scale() {
    return this.uniforms.scale.value
  }

  set shift(value) {
    this.uniforms.shift.value = value
  }

  get shift() {
    return this.uniforms.shift.value
  }

  set map(value) {
    this.uniforms.hasTexture.value = !!value
    this.uniforms.texture.value = value
  }

  get map() {
    return this.uniforms.texture.value
  }

  get color() {
    return this.uniforms.color.value
  }

  get opacity() {
    return this.uniforms.opacity.value
  }

  set opacity(value) {
    if (this.uniforms) this.uniforms.opacity.value = value
  }
}

extend({ FlowShader })
