import { render } from 'react-dom'
import React, { Suspense, useState, useEffect, useRef } from 'react'
import { Canvas } from 'react-three-fiber'
import { HTML } from 'drei'
import { Block, BlockX, BlockZ } from './blocks'
import { HtmlContent, Shapes, Box, Loading, Model, Chair, Spin, Image, Plane, Video, Hand, Lights, BgShade } from './components'
import state from './store'
import './styles.css'

// import img1 from './img/me_wide.jpg'
import briefIcon from './img/brief-icon.png'
import listIcon from './img/list-icon.png'
import growIcon from './img/grow-icon.png'


// import showreelUrl from 'https://vod-progressive.akamaized.net/exp=1607556459~acl=%2A%2F725980697.mp4%2A~hmac=3c9ba092e25fe8a07cb2564f50d1788345ca937a3e73bcd57a07d8d13a87aef6/vimeo-prod-skyfire-std-us/01/4863/7/199319944/725980697.mp4?filename=Amaticity+Showreel.mp4'
// const showreelUrl = 'https://player.vimeo.com/external/199319944.hd.mp4?s=4671b30b9599cdcc2c28d1619b578c26c65cb4ba&profile_id=174'
// const reelSquare = 'https://player.vimeo.com/external/489858404.hd.mp4?s=2673ef9c489f7c746508cc2c76717a31867e7695&profile_id=174'
function App() {
  const [events, setEvents] = useState()
  const domContent = useRef()
  const scrollArea = useRef()
  // const videoRef = useRef(null)
  // useEffect(() => {
  //   videoRef.current.play()
  // }, [videoRef])
  const onScroll = (e) => (state.top.current = e.target.scrollTop)
  useEffect(() => void onScroll({ target: scrollArea.current }), [])
  return (
    <>
      {/* <video
        ref={videoRef}
        autoPlay={true}
        muted={true}
        loop={true}
        playsInline={true}
        crossOrigin="anonymous"
        src={showreelUrl}
        style={{
          position: 'absolute',
          top: '-100%',
          left: '-100%',
          width: '100%',
          height: '100%'
        }}
      /> */}
      <Canvas
        shadowMap
        colorManagement
        gl={{ alpha: true, antialias: true }}
        camera={{ position: [0, 0, 4.5], fov: 50, near: 0.1, far: 9 }}
        attach="fog"
        onCreated={({ gl, events }) => {
          // gl.setClearColor(transparent)
          gl.toneMappingExposure = 2.5
          gl.toneMappingWhitePoint = 1
          // Export canvas events, we will put them onto the scroll area
          setEvents(events)
        }}>



          <Block factor={0.4} offset={0.1}>
          <HtmlContent portal={domContent}>
            <div className="nooverflow"><div className="topgradient"></div></div>
          </HtmlContent>
        </Block>

        
<Block factor={0.5} offset={0.1}>
          <HtmlContent portal={domContent}>
            <div className="menu ">
              <div className="logo">&nbsp;</div>
              <ul>
                <li>
                  <a href="#" className="nomobile">How it works?</a>
                </li>
                <li>
                  <a href="#">Log In</a>
                </li>
                <li><a href="#" className="button stroke ">Get started </a>
              </li>
              </ul>
            </div>
          </HtmlContent>
        </Block>

               
<Block factor={1} offset={0}>
          <HtmlContent portal={domContent}>
            <div className="manifesto">
            Read our <a href="#">Manifesto</a> or <a href="#">See how it works</a>
            </div>
          </HtmlContent>
        </Block>

        

        


        <BlockZ factor={3} offset={0} rotateFactor={2}>
          <Suspense fallback={false}>
            <Spin filePath={`models/spin.glb`} position={[0, 8, -2]} scale={[0.03, 0.03, 0.03]} speed={0.1} rotation={[0, 0, 0]} />
          </Suspense>
        </BlockZ>


        <Block factor={0.8} offset={0}>
          {/* <Box /> */}
          <HtmlContent portal={domContent}>
            <div className="jumbo">
            <p style={{paddingBottom: '15px'}}>You deserve to be happy.</p>
              <h1>
                {/* You deserve to be happy. */}
                Start feeling better, today.
              </h1>
              {/* <p>What type of counseling are you looking for?</p> */}
              {/* <p>Start feeling better, today.</p> */}
              {/* <p>Choose to feel better.</p> */}
              {/* <p>Talk to a concealer or therapist, today.</p> */}
              {/* <Categories /> */}
              <a href="#" className="button ">Get Started<span></span> </a>
                {/* <center><a href="#" style={{fontSize:'.7rem',paddingTop: '10px', fontWeight:'500',opacity: '0.5'}}>Log In</a></center> */}
            </div>
          </HtmlContent>
        </Block>

        

        <Block factor={0} offset={0.3}>
          <Lights />
        </Block>
       


<Block factor={1.1} offset={2.2}>
              <HtmlContent portal={domContent}>
            <div className="process-title">
              <h2>How Mindspace works</h2>
            </div>
          </HtmlContent>
        </Block>

        <Block factor={1.1} offset={2.4}>
          <HtmlContent portal={domContent}>
         
            <br />
            <div className="process-flex">
              <div className="process-box">
                <img src={briefIcon} />
                <h2>Brief assessment</h2>
                <p>Answer a few questions about your preferences.</p>
                
              </div>
              <div className="process-box">
                <img src={listIcon} />
                <h2>Pick your therapist</h2>
                <p>Select from a list of recommendations.</p>
               
              </div>
              <div className="process-box">
                <img src={growIcon} />
                <h2>Start therapy</h2>
                <p>Begin the journey towards a happier you.</p>
               
              </div>
              
            </div>
<a href="#" className="button green">Get Started<span></span></a>
           
          </HtmlContent>
        </Block>

        {/* <Block factor={-2} offset={4}>
          <Box scale={[2, 2, 2]} />
          <HTML center portal={domContent}>
            <h2>footer</h2>
          </HTML>
        </Block> */}
      </Canvas>

      <div className="scrollArea" ref={scrollArea} onScroll={onScroll} {...events}>
        <div style={{ position: 'sticky', top: 0 }} ref={domContent} />
        <div style={{ height: `${state.pages * 100}vh` }} />
      </div>
    </>
  )
}

render(<App />, document.querySelector('#root'))
