import React, { createContext, useRef, useContext } from 'react'
import { useFrame, useThree } from 'react-three-fiber'
import lerp from 'lerp'
import state from './store'

const offsetContext = createContext(0)

function Block({ children, offset, factor, ...props }) {
  const { offset: parentOffset, sectionHeight, aspect } = useBlock()
  const ref = useRef()
  offset = offset !== undefined ? offset : parentOffset
  useFrame(() => {
    const curY = ref.current.position.y
    const curTop = state.top.current / aspect
    ref.current.position.y = lerp(curY, (curTop / state.zoom) * factor, 0.1)
  })
  return (
    <offsetContext.Provider value={offset}>
      <group {...props} position={[0, -sectionHeight * offset * factor, 0]}>
        <group ref={ref}>{children}</group>
      </group>
    </offsetContext.Provider>
  )
}

function BlockX({ children, offset, factor, ...props }) {
  const { offset: parentOffset, sectionHeight, viewportWidth, aspect } = useBlock()
  const ref = useRef()
  offset = offset !== undefined ? offset : parentOffset
  useFrame(() => {
    const curX = ref.current.position.x
    const curTop = state.top.current / aspect
    ref.current.position.x = lerp(curX, (curTop / state.zoom) * factor, 0.1)
  })
  return (
    <offsetContext.Provider value={offset}>
      <group {...props} position={[-viewportWidth * offset * factor, 0, 0]}>
        <group ref={ref}>{children}</group>
      </group>
    </offsetContext.Provider>
  )
}

function BlockZ({ children, offset, factor, rotateFactor, ...props }) {
  const { offset: parentOffset, sectionHeight, aspect } = useBlock()
  const ref = useRef()
  offset = offset !== undefined ? offset : parentOffset
  useFrame(() => {
    const curZ = ref.current.position.z
    const curR = ref.current.rotation.y
    const curTop = state.top.current / aspect
    ref.current.position.z = lerp(curZ, -(curTop / state.zoom) * factor, 0.1)
    ref.current.rotation.y = lerp(curR, -(curTop / state.zoom) * rotateFactor, 0.01)
  })
  return (
    <offsetContext.Provider value={offset}>
      <group {...props} position={[0, 0, sectionHeight * offset * factor]}>
        <group ref={ref}>{children}</group>
      </group>
    </offsetContext.Provider>
  )
}

function useBlock() {
  const { sections, pages, zoom } = state
  const { size, viewport } = useThree()
  const offset = useContext(offsetContext)
  const viewportWidth = viewport.width
  const viewportHeight = viewport.height
  const canvasWidth = viewportWidth / zoom
  const canvasHeight = viewportHeight / zoom
  const mobile = size.width < 700
  const margin = canvasWidth * (mobile ? 0.2 : 0.1)
  const contentMaxWidth = canvasWidth * (mobile ? 0.8 : 0.6)
  const sectionHeight = canvasHeight * ((pages - 1) / (sections - 1))
  const aspect = size.height / viewportHeight
  return {
    aspect,
    viewport,
    offset,
    viewportWidth,
    viewportHeight,
    canvasWidth,
    canvasHeight,
    mobile,
    margin,
    contentMaxWidth,
    sectionHeight
  }
}

export { Block, BlockX, BlockZ, useBlock }
