import { VideoTexture, UniformsUtils, TextureLoader } from 'three/src/Three'
import React, { useRef, useMemo, useEffect, useState, useMouse } from 'react'
import { useFrame, useThree, useLoader } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
// import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { HTML, softShadows } from 'drei'
import * as THREE from 'three' //for clock only; need to rerwite clock const
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { useBlock } from './blocks'
import state from './store'
import lerp from 'lerp'
import './Shaders/Fade'
import './Shaders/CustomMaterial'
import './Shaders/FlowShader'
import Shadows from './Components/Shadows'
import { MinimumShader } from './Shaders/MinimumShader'
import { LiquidMaterial } from './Shaders/LiquidMaterial'

softShadows()

function TextureforVideo({ texture, args }) {
  return (
    <mesh>
      <planeBufferGeometry attach="geometry" args={args} />
      {/* <customMaterial attach="material" map={texture} /> */}
      <shaderMaterial
        attach="material"
        transparent
        args={[
          {
            ...MinimumShader,
            uniforms: UniformsUtils.clone(MinimumShader.uniforms)
          }
        ]}
        uniforms-texture-value={texture}
      />
    </mesh>
  )
}
export const Video = ({ video, position, ratio }) => {
  const front = new VideoTexture(video.current)
  return <TextureforVideo texture={front} position={position} args={ratio} />
}

export const ComputerScreen = ({ video, position, ratio }) => {
  const front = new VideoTexture(video.current)
  return <TextureforVideo texture={front} opacity={0.4} position={position} args={ratio} />
}

export function HtmlContent({ className, style, children, portal }) {
  const { size } = useThree()
  return (
    <HTML
      portal={portal}
      style={{
        position: 'absolute',
        top: -size.height / 2,
        left: -size.width / 2,
        width: size.width,
        height: size.height
      }}>
      <div className={className} style={style}>
        {children}
      </div>
    </HTML>
  )
}

export function useWobble(factor = 1, fn = 'sin', cb) {
  const ref = useRef()
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    ref.current.position.y = Math[fn](t) * factor
    if (cb) cb(t, ref.current)
  })
  return ref
}

export function Box(props) {
  const [hovered, set] = useState(false)
  const ref = useWobble(0.5, 'cos')
  useFrame(() => (ref.current.rotation.x = ref.current.rotation.y = ref.current.rotation.z += 0.01))
  return (
    <mesh castShadow ref={ref} {...props} onPointerOver={() => set(true)} onPointerOut={() => set(false)}>
      <boxBufferGeometry attach="geometry" />
      <meshStandardMaterial attach="material" color={hovered ? 'hotpink' : 'white'} />
    </mesh>
  )
}

export function Shapes() {
  const {
    viewport: { width, height }
  } = useThree()
  const ringSize = Math.max(3, width / 2)
  const crossSize = 0.7
  return (
    <>
      <Ring position={[-width * 0.8, height * -3, -5]} scale={[ringSize, ringSize, 1]} />
      {/* <Cross position={[-width / 2.5, height / 8, -1]} scale={[crossSize, crossSize, 1]} rotation={[0, 0, Math.PI / 4]} /> */}
      {/* <Minus position={[width / 3, -height / 3.5, -2]} scale={[0.8, 0.8, 0.8]} rotation={[0, 0, Math.PI / 10]} /> */}
      <group rotation={[Math.PI / 10, 0, 0]} position={[-width / 4, -height / 6, 0]}>
        {/* <Box position={[width / 10, -height / 2, 1]} scale={[0.5, 0.5, 0.8]} /> */}
        {/* <Box position={[width / 1.5, height / 4, -3]} scale={[1, 1, 1]} /> */}
      </group>
    </>
  )
}

function Ring(props) {
  return (
    <mesh {...props}>
      <ringBufferGeometry attach="geometry" args={[1, 1.4, 64]} />
      <meshBasicMaterial attach="material" color="#fefefe" transparent opacity={1} toneMapped={false} />
    </mesh>
  )
}

function Cross(props) {
  const inner = useRef()
  const ref = useWobble(0.1, 'sin', () => (inner.current.rotation.z += 0.001))
  return (
    <group ref={ref}>
      <group ref={inner} {...props}>
        <mesh>
          <planeBufferGeometry attach="geometry" args={[2, 0.5]} />
          <meshBasicMaterial attach="material" color="#FFEDDD" toneMapped={false} />
        </mesh>
        <mesh position={[0, -0.625, 0]}>
          <planeBufferGeometry attach="geometry" args={[0.5, 0.75]} />
          <meshBasicMaterial attach="material" color="#FFEDDD" toneMapped={false} />
        </mesh>
        <mesh position={[0, 0.625, 0]}>
          <planeBufferGeometry attach="geometry" args={[0.5, 0.75]} />
          <meshBasicMaterial attach="material" color="#FFEDDD" toneMapped={false} />
        </mesh>
      </group>
    </group>
  )
}

function Minus(props) {
  const ref = useWobble(0.1, 'sin')
  return (
    <group ref={ref}>
      <group {...props}>
        <mesh>
          <planeBufferGeometry attach="geometry" args={[2, 0.7]} />
          <meshBasicMaterial attach="material" color="#DEF5FF" toneMapped={false} transparent opacity={0.7} />
        </mesh>
      </group>
    </group>
  )
}

export function Lights() {
  return (
    <>
      <ambientLight intensity={0.1} />
      <pointLight position={[-7, 0, 10]} intensity={1} angle={0.3} penumbra={1}  />
      <pointLight position={[1, -1, 15]} intensity={.1} color="#dc0073" castShadow />
      <spotLight position={[7, -5, 5]} intensity={1} color="#fe5924" castShadow/>
      <spotLight position={[-7, 5, 5]} intensity={1} color="#fe5924" castShadow/>

      <Shadows
        // ref={shadow}
        rotation={[Math.PI / 2, 0, 0]}
        position={[0, -1.85, 0]}
        doublePass
        opacity={0.4}
        width={30}
        height={30}
        blur={2}
        far={3}
        transparent
      />
    </>
  )
}

export function Loading() {
  return (
    <mesh visible position={[0, 0, 0]} rotation={[0, 0, 0]}>
      {/* <sphereGeometry attach="geometry" args={[1, 1, 1]} /> */}
      {/* <meshStandardMaterial attach="material" color="white" transparent opacity={1} roughness={1} metalness={0} /> */}
    </mesh>
  )
}

export function Model({ filePath, position, scale, speed, rotation }) {
  const scene = useRef()
  const shadow = useRef()
  const gltf = useLoader(GLTFLoader, filePath)
  // const sine = Math.sin(state.clock.getElapsedTime())

  useFrame(() => {
    scene.current.rotation.y += speed
    scene.current.position.y = lerp(scene.current.position.y, -2, 0.02)
  })
  return (
    <group>
      <primitive
        ref={scene}
        object={gltf.scene}
        castShadow
        receiveShadow
        position={position}
        rotation={rotation}
        scale={scale}
        metalness={1}
        roughness={0}
      />
    </group>
  )
}

export function Chair({ filePath, position, scale, speed, rotation }) {
  const scene = useRef()
  const shadow = useRef()
  const gltf = useLoader(GLTFLoader, filePath)

  // const sine = Math.sin(state.clock.getElapsedTime())

  useFrame(() => {
    // scene.current.rotation.y += speed
    scene.current.rotation.y = lerp(scene.current.rotation.y, -2, 0.02)
    scene.current.position.y = lerp(scene.current.position.y, -2, 0.02)
    // scene.current.position.x = lerp(scene.current.position.x, 0, 0.02)
    scene.current.position.z = lerp(scene.current.position.z, 0.2, 0.02)
  })
  return (
    <group>
      <primitive
        ref={scene}
        object={gltf.scene}
        castShadow
        receiveShadow
        position={position}
        rotation={rotation}
        scale={scale}
        metalness={1}
        roughness={0}
      />
    </group>
  )
}

export function Spin({ filePath, position, scale, speed, rotation }) {
  const scene = useRef()
  const shadow = useRef()
  const gltf = useLoader(GLTFLoader, filePath)
  // const sine = Math.sin(state.clock.getElapsedTime())

  useFrame(() => {
    scene.current.rotation.y += speed
    scene.current.rotation.z = 0.02 // to see the spin
    scene.current.position.y = lerp(scene.current.position.y, -1.8, 0.02)
  })
  return (
    <group>
      <primitive
        ref={scene}
        object={gltf.scene}
        castShadow
        receiveShadow
        position={position}
        rotation={rotation}
        scale={scale}
        metalness={1}
        roughness={0}
      />
    </group>
  )
}

// export function Spin(filePath, position, scale, speed, rotation, props) {
//   const group = useRef()
//   const { nodes } = useLoader(GLTFLoader, '/models/spin.glb', (loader) => {
//     const dracoLoader = new DRACOLoader()
//     dracoLoader.setDecoderPath('/draco-gltf/')
//     loader.setDRACOLoader(dracoLoader)
//   })

//   useFrame(() => {
//     group.current.rotation.y += speed
//     group.current.position.y = lerp(group.current.position.y, -2, 0.015)
//   })

//   return (
//     <group {...props} dispose={null}>
//       <group ref={group} position={position} rotation={rotation} scale={scale}>
//         <mesh geometry={nodes.Spin.geometry}>
//           <meshStandardMaterial attach="material" color="#FF0000" roughness={0.5} metalness={0} />
//         </mesh>
//       </group>
//     </group>
//   )
// }

export function Hand({ filePath, position, scale, speed, rotation }) {
  const scene = useRef()
  const gltf = useLoader(GLTFLoader, filePath)
  // console.log('rendering', gltf)
  useFrame(() => {
    scene.current.rotation.y += speed
    scene.current.position.y = lerp(scene.current.position.y, -2, 0.015)
  })
  return (
    // <mesh>
    //   <meshStandardMaterial ref={scene} material={gltf.scene} map={gltf.scene} position={position} rotation={rotation} scale={scale} />
    // </mesh>
    <group>
      <primitive
        ref={scene}
        material="material"
        object={gltf.scene}
        castShadow
        receiveShadow
        position={position}
        rotation={rotation}
        scale={scale}
      />
      {/* <customMaterial ref={scene} attach="material" map={gltf.scene} position={position} rotation={rotation} scale={scale} /> */}
      {/* <meshBasicMaterial attach="material" color="#DEF5FF" toneMapped={false} transparent opacity={0.7} /> */}
    </group>
  )
}

export function Image({ url, position, ratio, ...props }) {
  // const {
  //   viewport: { width, height }
  // } = useThree()
  const ref = useRef()
  const [hovered, setHover] = useState(false)
  const texture = useLoader(TextureLoader, url)
  let last = state.top.current
  // useFrame(() => (ref.current.material.dispFactor = lerp(ref.current.material.dispFactor, hovered ? 1 : 0, 0.03)))
  useFrame(() => {
    const { top } = state
    ref.current.material.shift = lerp(ref.current.material.shift, (top.current - last) / 150, 0.2)
    last = top.current
  })
  return (
    <mesh position={position} ref={ref} {...props} onPointerOver={() => setHover(true)} onPointerOut={() => setHover(false)}>
      <planeBufferGeometry attach="geometry" args={ratio} />
      {/* <fade attach="material" texture={texture1} texture2={texture2} disp={dispTexture} /> */}
      <customMaterial attach="material" map={texture} />
    </mesh>
  )
}

export function LiquidShade({ color = 'none', map, ...props }) {
  const { viewportHeight, offsetFactor, viewportWidth } = useBlock()
  var startTime = Date.now()
  var clock = new THREE.Clock()
  const material = useRef()
  let last = state.top.current
  useFrame(() => {
    const { pages, top } = state
    material.current.iGlobalTime += clock.getDelta() * 0.25
    material.current.iResolution.x = viewportWidth
    material.current.iResolution.y = viewportHeight
    if (material.current.shift <= 1) {
      material.current.shift = lerp(material.current.shift, (top.current / ((pages - 1) * viewportHeight)) * 6.5, 0.02)
      material.current.offset = lerp(material.current.offset, 0 + (top.current / ((pages - 1) * viewportHeight)) * 10, 0.025)
    } else {
      material.current.shift = 1
      material.current.offset = 0.1
    }
    last = top.current
  })
  return (
    <mesh {...props}>
      <planeBufferGeometry attach="geometry" args={[2, 2, 2, 2]} />
      <liquidMaterial ref={material} attach="material" color={color} map={map} />
    </mesh>
  )
}

export function BgShade() {
  const { viewportHeight, viewportWidth } = useBlock()
  return <LiquidShade scale={[viewportWidth, viewportHeight, 1]} position={[0, 0, -10]} color="#fafbfc" />
}
