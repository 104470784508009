import { ShaderMaterial, Color } from 'three'
import * as THREE from 'three'
import { extend } from 'react-three-fiber'

class LiquidMaterial extends ShaderMaterial {
  constructor() {
    super({
      vertexShader: `uniform float iGlobalTime;
        uniform vec2 iResolution;
        uniform vec4 iMouse;
        // uniform float audio1;
        uniform sampler2D iChannel0;
        uniform sampler2D iChannel1;

        varying vec2 vUv;
        void main() {
          vUv = uv;
          vec4 mvPosition = modelViewMatrix * vec4(position, 0.1 );
          gl_Position = projectionMatrix * mvPosition;
        }`,
      fragmentShader: `uniform float iGlobalTime;
      uniform vec2 iResolution;
      uniform vec4 iMouse;
      // uniform float audio1;
      uniform sampler2D iChannel0;
      uniform sampler2D iChannel1;
      uniform float shift;
      uniform float opacity;
      uniform float offset;
      uniform float scale;


      #define t iGlobalTime
      mat2 m(float a){float c=cos(a), s=sin(a);return mat2(c,-s,s,c);}
      float map(vec3 p){
        // p.xz*= m(1.2);p.xy*= m(0.1)+offset*1.9 ;
        p.yx*= m(13.5)+offset;p.
        xy*= m(1.4)-offset ; // flag like motion
          vec3 q = p*3.+t;
          return  p.x*p.y * length(p+vec3(sin(0.5)))*log(length(p)+1.) + sin(q.x+sin(q.z+sin(q.y)))*0.5 - 1. ;
      }

      void main(){
        vec2 p = gl_FragCoord.xy/iResolution.y - vec2(0.8,.5)  ;

          vec3 cl = vec3(.0 + offset);
          float d = .5 + (offset * -.1);
          for(int i=0; i<=5; i++) {
          vec3 p = vec3(0,-.2,4) + normalize(vec3(p, - 2.5))*d ;
              float rz = map(p);
          float f =  clamp((rz - map(p+.1))*0.5*cos(iGlobalTime*.1)*p.x, -.1, 1. );
              vec3 l = vec3(0.1,0.1,.4) + vec3(3., 1.5, 3.)*f;
              cl = cl*l + (1.-smoothstep(0.6,8.5, rz))*.7*l;
          d += min(rz, 1.0  );
        }
          gl_FragColor = vec4(cl, opacity);
      }`,
      uniforms: {
        iGlobalTime: { type: 'f', value: 0.0 },
        iResolution: { type: 'v2', value: new THREE.Vector2() },
        iChannel0: { type: 't', value: THREE.ImageUtils.loadTexture('../img/disp.jpg') },
        shift: { value: 1 },
        opacity: { value: 1 },
        offset: { value: 1 },
        scale: { value: 1 }
      }
    })
  }

  set iGlobalTime(value) {
    this.uniforms.iGlobalTime.value = value
  }

  get iGlobalTime() {
    return this.uniforms.iGlobalTime.value
  }

  set iResolution(value) {
    this.uniforms.iResolution.value = value
  }

  get iResolution() {
    return this.uniforms.iResolution.value
  }

  set offset(value) {
    this.uniforms.offset.value = value
  }

  get offset() {
    return this.uniforms.offset.value
  }

  set shift(value) {
    this.uniforms.shift.value = value
  }

  get shift() {
    return this.uniforms.shift.value
  }

  set scale(value) {
    this.uniforms.scale.value = value
  }

  get scale() {
    return this.uniforms.scale.value
  }

  // set map(value) {
  //   this.uniforms.hasTexture.value = !!value
  //   this.uniforms.texture.value = value
  // }
  //
  // get map() {
  //   return this.uniforms.texture.value
  // }
  //
  // get color() {
  //   return this.uniforms.color.value
  // }
  //
  get opacity() {
    return this.uniforms.opacity.value
  }

  set opacity(value) {
    if (this.uniforms) this.uniforms.opacity.value = value
  }
}

extend({ LiquidMaterial })
